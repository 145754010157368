import {API} from '../API';
import {formatDate} from '../helpers';

const reports = {
    orders: async (from, to) => {
        const {data: response} = await API.get(
            `/reports/orders?from=${formatDate(from)}&to=${formatDate(to)}`,
        );
        return response.data;
    },
    activeUsers: async () => {
        const {data: response} = await API.get(`/reports/active-users`);
        return response.data;
    },
    newUsers: async () => {
        const {data: response} = await API.get(`/reports/new-users`);
        return response.data;
    },
    newProductsAndSold: async (from, to) => {
        const {data: response} = await API.get(
            `/reports/products-sold-and-new?from=${formatDate(
                from,
            )}&to=${formatDate(to)}`,
        );
        return response.data;
    },
};

export default reports;
