import React, {useEffect, useState} from 'react';
import {sub} from 'date-fns';
import {useIntl} from 'react-intl';
import Card from 'react-bootstrap/Card';
import {Row, Col} from 'react-bootstrap';
import ReportCard from '../../components/reports/ReportCard';
import API from '../../common/utils/API';

function Dashboard() {
    const intl = useIntl();
    // variable for the reports
    const [activeUsers, setActiveUsers] = useState(0);
    const [newUsersCount, setNewUsersCount] = useState(0);
    const [orders, setOrders] = useState([]);
    const [ordersStartDate, setOrdersStartDate] = useState(
        sub(new Date(), {
            months: 1,
        }),
    );
    const [ordersEndDate, setOrdersEndDate] = useState(new Date());
    const [productsReport, setProductsReport] = useState([]);
    const [productsStartDate, setProductsStartDate] = useState(
        sub(new Date(), {
            months: 1,
        }),
    );
    const [productsEndDate, setProductsEndDate] = useState(new Date());

    ///////////////////////////////////////
    ////////////// FUNCTIONS //////////////
    ///////////////////////////////////////

    // when orders report dates change
    const onOrdersDateRangeChange = (startDate, endDate) => {
        setOrdersStartDate(startDate);
        setOrdersEndDate(endDate);
    };

    // when products report dates change
    const onProductsDateRangeChange = (startDate, endDate) => {
        setProductsStartDate(startDate);
        setProductsEndDate(endDate);
    };

    // query orders report when page is loaded or date range has changed
    useEffect(() => {
        API.reports.orders(ordersStartDate, ordersEndDate).then((response) => {
            setOrders(response);
            console.log('Orders response', response);
        });
    }, [ordersStartDate, ordersEndDate]);

    // query products report when page loads or date range changes
    useEffect(() => {
        API.reports
            .newProductsAndSold(productsStartDate, productsEndDate)
            .then((response) => {
                setProductsReport(response);
                console.log('products reponse', response);
            });
    }, [productsStartDate, productsEndDate]);

    // query active users and new users reports once
    useEffect(() => {
        API.reports.activeUsers().then((response) => {
            setActiveUsers(response.total);
        });
        API.reports.newUsers().then((response) => {
            setNewUsersCount(response.total);
        });
    }, []);

    return (
        <>
            <Row>
                <Col sm={9} className='pb-4'>
                    <ReportCard
                        startDate={ordersStartDate}
                        endDate={ordersEndDate}
                        onDateRangeChange={onOrdersDateRangeChange}
                        title='Pedidos e ingresos'
                        series={[
                            {
                                name: 'Pedidos',
                                dataKey: 'count',
                                color: '#82ca9d',
                            },
                            {
                                name: 'Ingresos',
                                dataKey: 'fees',
                                color: '#8884d8',
                                yAxisId: 'axis2',
                            },
                        ]}
                        data={orders}
                        YAxisNumber={2}
                    ></ReportCard>
                </Col>
                <Col sm={3}>
                    <Card className='text-center mb-5'>
                        <Card.Header>Usuarios activos</Card.Header>
                        <Card.Body>
                            <h1>{activeUsers}</h1>
                            <Card.Text>Últimos 30 dias</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className='text-center mb-5'>
                        <Card.Header>Usuarios usando puja</Card.Header>
                        <Card.Body>
                            <h1>0</h1>
                            <Card.Text>Últimos 30 dias</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className='text-center mb-5'>
                        <Card.Header>Nuevos usuarios</Card.Header>
                        <Card.Body>
                            <h1>{newUsersCount}</h1>
                            <Card.Text>Últimos 30 dias</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={9}>
                    <ReportCard
                        title='Nuevos productos vs productos vendidos'
                        startDate={productsStartDate}
                        endDate={productsEndDate}
                        onDateRangeChange={onProductsDateRangeChange}
                        series={[
                            {
                                name: 'Nuevos',
                                dataKey: 'new',
                                color: '#82ca9d',
                            },
                            {
                                name: 'Vendidos',
                                dataKey: 'sold',
                                color: '#8884d8',
                            },
                        ]}
                        data={productsReport}
                        YAxisNumber={2}
                    />
                </Col>
            </Row>
        </>
    );
}

export default Dashboard;
